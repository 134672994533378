import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDM_yFE0wplSIbCepHcOGQQpKFGDM2-pro",
  authDomain: "enim-9a5f6.firebaseapp.com",
  projectId: "enim-9a5f6",
  storageBucket: "enim-9a5f6.appspot.com",
  messagingSenderId: "242001959683",
  appId: "1:242001959683:web:42349f65e9a87d0f7d6736",
  measurementId: "G-C560C6DKPM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
