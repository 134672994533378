import React, { useEffect, useState } from "react";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import logo from '../../img/enim_logo.png'

export default function Navbar() {
  const cr_pg = window.location.pathname;
  const prj_nm = cr_pg.substring(10, cr_pg.length);
  const [prj_nm_l, setPrj_nm_l] = useState(prj_nm);

  useEffect(
    () => {
      prj_nm_l !== "" ? setPrj_nm_l("") : setPrj_nm_l("/" + prj_nm);
    },
    [cr_pg, prj_nm]
  );

  const screen = [
    {
      title: "Projects",
      link: "/projects" + prj_nm_l,
      pg: prj_nm
    },
    // {
    //   title: prj_nm,
    //   link: "/projects/" + prj_nm,
    //   pg: prj_nm
    // },
    {
      title: "Contact",
      link: "/contact",
      pg: ""
    }
  ];

  // console.log(screen[0].link + " + " + cr_pg);

  return (
    <div className="navbar_wrap">
      <div className="navbar_logo">
        <NavLink
          to=""
          activeClassName=""
          onClick={e => {
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }}
        >
          <img src={logo} alt="en+im logo" height='30px' />
        </NavLink>
      </div>
      <ul className="navbar_navigation_wrap">
        {screen.map((scrn, i) => {
          return (
            <div key={i}>
              <li className="navbar_navigation_itm">
                <NavLink
                  to={scrn.link}
                  className={window.location.pathname === scrn.link && scrn.pg === prj_nm ? "nav_link nav_active" : "nav_link"}
                  onClick={e => {
                    window.location.reload();
                    window.location.replace(scrn.link);
                  }}
                >
                  {scrn.title}
                </NavLink>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
}
