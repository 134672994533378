import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useSearchParams, useParams, useLocation } from "react-router-dom";
import './css/project_landing.css'
import kaizen_wall from "../img/projects/kaizen/kaizen_wallpaper.png";
import nudge_wall from "../img/projects/nudge/nudge_wallpaper.png";
import stocksync_wall from "../img/projects/stocksync/stocksync_wallpaper.png";
import ffw_redesign_wall from "../img/projects/ffw_redesign/ffw_redesign_wallpaper.png";
// import '../img/projects/';

import { Fade, JackInTheBox, Zoom, Bounce } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from 'swiper/react';

export default function ProjectLanding() {

  const location = useLocation()?.pathname;

  const projects = [
    {
      title: "Kaizen",
      link: "kaizen",
      img: kaizen_wall
    },
    {
      title: "Nudge",
      link: "nudge",
      img: nudge_wall
    },
    {
      title: "StockSync",
      link: "stocksync",
      img: stocksync_wall
    },
    {
      title: "FFW Redesign",
      link: "ffw_redesign",
      img: ffw_redesign_wall
    }
  ];

  const [projectName, setProjectName] = useState(location?.substring(10, location?.length))

  useEffect(() => {
    setProjectName(location?.substring(10, location?.length));

    console.log(location)
    console.log(projectName)

  }, [location, projectName])

  return (
    <div className="prj_land_wrap">

      <Outlet />
      <div>
        <hr className="other-projects-hr" style={{
          border: '2px solid #dedcda',
          display: projectName === "" ? "none" : "block"
        }} />
        <div style={{ fontFamily: 'Bebas Neue', marginTop: '20px', paddingLeft: '20px', fontSize: '24px', color: '#4B4541' }}>
          {/* All Projects */}
          {
            projectName === "" ? 'All Projects' : 'More Projects'
          }
        </div>
      </div>
      <ul className="prj_land_ul" >
        {projects.map((prjct, i) => {

          if (projectName !== "" && projectName !== prjct?.link) {
            return (
              <>
                <Bounce forever delay={500 + (i * 500)}>
                  <li className="prj_land_li">
                    <NavLink to={prjct.link} style={{ padding: 0, margin: "0" }}>
                      {/* {prjct.title} */}
                      <img className="prj_coverImg" style={{ width: "100%" }} src={prjct.img} alt={prjct.title} />
                      <div className="prj_title" style={{
                        fontFamily: 'Montserrat',
                        fontSize: '0.75rem',
                        fontWeight: '600'
                      }}
                      >
                        Project {i + 1}: {prjct?.title}
                      </div>
                    </NavLink>
                  </li>
                </Bounce>
              </>
            );
          } else if (projectName === "") {
            return (
              <>
                <Bounce forever delay={500 + (i * 500)}>
                  <li className="prj_land_li">
                    <NavLink to={prjct.link} style={{ padding: 0, margin: "0" }}>
                      {/* {prjct.title} */}
                      <img className="prj_coverImg" style={{ width: "100%" }} src={prjct.img} alt={prjct.title} />
                      <div className="prj_title" style={{
                        fontFamily: 'Montserrat',
                        fontSize: '0.75rem',
                        fontWeight: '600'
                      }}
                      >
                        Project {i + 1}: {prjct?.title}
                      </div>
                    </NavLink>
                  </li>
                </Bounce>
              </>
            )
          }

        })}
      </ul>
    </div>
  );
}
