import React from "react";
import "./screens.css";

import { Fade, JackInTheBox, Zoom, Bounce } from "react-awesome-reveal";

import img from "../img/eddie_banner_col.png";

export default function About() {
  const contacts = [
    {
      logo: "",
      href: "",
      text: "Gmail"
    },
    {
      logo: "",
      href: "",
      text: "Instagram"
    },
    {
      logo: "",
      href: "",
      text: "LinkedIn"
    }
  ];
  return (
    <div className="about_wrap">
      <JackInTheBox forever delay={500}>
        <img className="home_welcome_img" src={img} alt="Eddie Sosera"
          style={{ width: '120px', height: '120px', borderRadius: '90px' }} />
      </JackInTheBox>
      <Bounce forever delay={1100}>
        <div className="" style={{ fontFamily: 'Bebas Neue', color: "#F96706", fontSize: '48px', marginTop: '20px' }}>
          Lets Chat
        </div>
      </Bounce>
      <div className="about_txt" style={{ width: window.screen.width > 470 ? "30%" : "75%", textAlign: '', marginTop: '10px' }}>
        {/* Don't hesitate to reach out - drop me a message or connect with me on social media.
        <br /> */}
        Let's start a conversation and <br />explore the possibilities together!
      </div>
      <div className="about_btn_wrap">
        <Bounce forever delay={1000}>
          <a href="mailto:engineeredximagination@gmail.com?subject=Work inquiry&body=Hi, I hope I find you well. I am writing this email to enquire about...">
            <button className="about_btn">Lets Chat</button>
          </a>
        </Bounce>
        <Bounce forever delay={1300}>
          <a href="https://github.com/eddiesosera">
            <button className="about_btn">GitHub</button>
          </a>
        </Bounce>
        <Bounce forever delay={1300}>
          <a href="https://www.instagram.com/engineeredimagination/">
            <button className="about_btn">Instagram</button>
          </a>
        </Bounce>
      </div>
    </div >
  );
}
