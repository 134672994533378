import React, { useEffect, useRef, useState } from 'react';
import "./screens.css";
import "./css/home.css";
import 'remixicon/fonts/remixicon.css';

import { Fade, JackInTheBox, Zoom, Bounce } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Mousewheel, Pagination } from 'swiper/modules';

// import img from "../img/eddie_banner_col.png";
import elevator_pitch from "../img/elevator_pitch.mp4";


// V2 Imports

import imgv2 from "../img/eddie_landing3.svg"

export default function Home() {
  const [activeIndex, setActiveIndex] = useState(0);

  const topFiller = () => {
    switch (activeIndex) {
      case 0:
        return '#FFFEFD'
        break;
      case 1:
        return '#F7F4F2'
        break;
      case 2:
        return '#FFFEFD'
        break;
    }
  }

  const skills = [
    // {
    //   label: 'Visual Design & Art Direction',
    //   description: 'Transforming ideas into captivating digital interfaces. I blend color, typography, and imagery to create visually stunning designs that align with your brand.'
    // },
    {
      label: 'User Experience Design',
      description: 'Shaping interfaces for user satisfaction and functionality. I dive deep into understanding your audience to create immersive and enjoyable digital experiences.'
    },
    {
      label: 'Wireframing',
      description: 'Crafting the blueprint for seamless digital journeys. My wireframes ensure every element has a purpose, laying the foundation for an intuitive user experience.'
    },
    {
      label: 'Prototyping',
      description: 'Bringing ideas to life through interactive previews. Prototypes allow you to test the flow and functionality before development, providing a tangible user experience.'
    },
    {
      label: 'Art Direction',
      description: 'Elevating design to an art form. As your art director, I curate visuals, from color palettes to imagery, to evoke desired emotions and leave a lasting impression.'
    },
    // {
    //   label: 'User Research',
    //   description: 'Understanding your audience profoundly. Through surveys and analytics, I gather insights into user behaviors, guiding design decisions to align with user expectations.'
    // }
  ];

  useEffect(() => {
    console.log(topFiller())
  }, [activeIndex]);


  return (

    <div className="home_welcome_wrap" style={{ width: '99vw' }}>
      <div style={{ background: topFiller(), height: '6vh', width: '100%', transition: 'all linear 0.3s' }}></div>
      <Swiper
        onActiveIndexChange={e => { console.log(e.activeIndex); setActiveIndex(e.activeIndex) }}
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={30}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
        style={{ width: '100%', height: '100%', }}>
        <SwiperSlide style={{ width: '100%', height: '100%' }}>
          <Zoom cascade damping={0.3} bottom>
            <div className="home_section1" style={{ height: '80vh' }}>
              <div className="home_section1-left">
                <Bounce delay={500} cascade damping={1.5}>
                  <div className="home_section1-left-top">
                    <div className="home_section1-left-header">
                      <div className="home_section1-left-header-top">
                        Turn Ideas <br />
                        into Exceptional
                      </div>
                      <div className="home_section1-left-header-btm">
                        User Experiences.
                      </div>
                    </div>
                    <div className="home_section1-left-text">
                      I am passionate about elevating User Experience.
                      Let's connect to transform your brand.
                    </div>
                  </div>
                </Bounce>
                <Bounce forever delay={1750}>
                  <button className="cta cta-icon">
                    <i class="ri-arrow-down-double-line"></i>
                  </button>
                </Bounce>
              </div>

              <div className="home_section1-right" style={{ width: '50%', padding: '20px' }}>
                <Bounce forever delay={1050}>
                  <img src={imgv2} alt="Eddie Landing" className='landing_image' style={{
                    height: '102.25%',
                    // maxWidth: '75vh' 
                  }} />
                </Bounce>
              </div>
            </div>
          </Zoom>
        </SwiperSlide>
        <SwiperSlide>
          <Zoom cascade damping={0.3} bottom>
            <div className="home_section2" style={{ padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
              <div className="home_section1-left-header">
                <div className="home_section1-left-header-top">
                  About Me
                </div>
              </div>
              <div className="home_section1-left-text">
                My name is Eddie Sosera, a South African UI/UX Designer who is assionate UI designer with a flair for creating intuitive and visually appealing digital experiences. Transforming ideas into sleek, user-centric interfaces that marry form and function.
              </div>
              <div className="home_elevatorPitch_wrap" style={{ marginTop: '25px' }}>
                <video src={elevator_pitch} className="home_elevatorPitch" controls>
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </Zoom>
        </SwiperSlide>
        <SwiperSlide>
          <Zoom cascade damping={0.3} bottom>
            <div className="home_section3" style={{ padding: '25px', background: '#FFFEFD', paddingBottom: '25px', height: '99vh' }}>
              <div className="home_section3-left-header">
                <div className="home_section3-left-header">
                  <div className="home_section3-left-header-top text-heading-orange">
                    Services & Skills
                  </div>
                </div>
                <div className="home_section1-left-text">
                  Each service is a commitment to turning your vision into a visually compelling and user-friendly reality.
                </div>
                <div className='skills' style={{ marginTop: '50px' }}>
                  <div className='skills-top-wrap' style={{ marginBottom: '20px' }}>
                    <div style={{ fontFamily: 'Montserrat', fontWeight: '700', color: '#3F4448', fontSize: '1.25rem' }}>
                      Skills Offered</div>
                    <hr style={{ border: '.5px solid #ebebeb', maxWidth: '100vw' }} />
                  </div>
                  <div className='skills-top-wrap' style={{ display: 'flex', flexDirection: 'row', gap: '30px', marginTop: '25px', flexWrap: 'wrap', width: '90vw' }}>
                    {
                      skills?.map((skill, i) => {
                        return (
                          <Bounce delay={500 + (i * 500)} cascade damping={1.5}>
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '15px', border: '2px solid #eee', padding: '15px', borderRadius: '15px' }} key={i}>
                              <div className='numberWrap'>{i + 1}</div>
                              <div className='' style={{ display: 'flex', flexDirection: 'column', gap: '10px', }}>
                                <div className='home_section1-left-text text-head' style={{ height: '34px', display: 'flex', alignItems: 'center', }}>{skill?.label}</div>
                                {/* <div className='home_skill-paragraph'>{skill?.description}</div> */}
                              </div>
                            </div>
                          </Bounce>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </Zoom>
        </SwiperSlide>
      </Swiper >
    </div>

    // <div className="home_wrap">
    //   <div className="home_welcome_wrap">
    //     <div className="home_welcome_head prmry_head">HELLO, THERE</div>
    //     <div className="home_welcome_img_wrap">
    //       <img className="home_welcome_img" src={img} alt="Eddie Sosera" />
    //       {/* <div className="home_welcome_img_bg" src="" alt="txtr" /> */}
    //     </div>
    //     <div className="home_welcome_bio">
    //       "Welcome to Engineered Imagination."
    //       <br />
    //       <br />
    //       "Know more about me by watching the video below."
    //     </div>
    //   </div>
    //   <div className="home_elevatorPitch_wrap">
    //     <video src={elevator_pitch} className="home_elevatorPitch" controls>
    //       Your browser does not support the video tag.
    //     </video>
    //   </div>
    // </div>
  );
}
