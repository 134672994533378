import React from "react";
import Masonry from "react-responsive-masonry";
import "./style/projects.css";
import './style/v2.css'

// import kaizen_logo from "../../img/projects/kaizen/kaizen_logo_symbol.png";
// import kaizen_txt from "../../img/projects/kaizen/kaizen_logo_txt.png";
// import kaizen_banner from "../../img/projects/kaizen/files/kaizen_banner3.svg";
// import kaizen_designChallenge from "../../img/projects/kaizen/files/kaizen_designChallenge.png";

// import kaizen_designFrame from "../../img/projects/kaizen/files/design_frame/kaizen_designFrame_hmw.png";
// import kaizen_designSolution from "../../img/projects/kaizen/files/design_frame/kaizen_designFrame_solution.png";

// import kaizen_processInsight from "../../img/projects/kaizen/files/design_process/designProcess_interviewSummary_keyResearchTechniques.png";
// import kaizen_processFeatures from "../../img/projects/kaizen/files/design_process/designProcess_interviewSummary_featuresOfApplication.png";
// import kaizen_processPersona from "../../img/projects/kaizen/files/design_process/kaizen_persona.png";

// V2 Imports
import kaizen_banner2 from "../../img/projects/kaizen/v2/files/kaizen_banner.svg";
import kaizen_scope from "../../img/projects/kaizen/v2/files/kaizen_scope.svg";
import kaizen_research from "../../img/projects/kaizen/v2/files/kaizen_research.svg";
import kaizen_execution from "../../img/projects/kaizen/v2/files/kaizen_execution.svg";
import kaizen_finalSolution from "../../img/projects/kaizen/v2/files/kaizen_finalSolution.svg";
import kaizen_prototype from "../../img/projects/kaizen/v2/files/kaizen_prototype.svg";
import kaizen_mockup from "../../img/projects/kaizen/v2/files/kaizen_mockup.svg";
import kaizen_conclusion from "../../img/projects/kaizen/v2/files/kaizen_conclusion.svg";
import { VideoPlayer } from "../../util/Youtube";

export default function Kaizen() {
  // function importAll(r) {
  //   return r.keys().map(r);
  // }

  // const gf_bd = importAll(
  //   require.context("../../img/projects/kaizen/files/gamification_breakdown/", false, /\.(png|jpe?g|svg)$/)
  // );
  // const wrfms = importAll(require.context("../../img/projects/kaizen/files/wireframes/", false, /\.(png|jpe?g|svg)$/));
  // const vsl_dsgn = importAll(require.context("../../img/projects/kaizen/files/visual_design/", false, /\.(png|jpe?g|svg)$/));

  // // images.forEach(element => console.log(element.substring(14, 90)));

  // console.log(gf_bd.map(wrf => wrf.substring(14, 90)));

  // V1
  // return (
  //   <div>
  //     <div className="project_wrap">
  //       <div className="project_secction banner_section">
  //         {/* <div className="section_heading prmry_head">Kaizen</div> */}
  //         <img src={kaizen_banner} alt="Logo" className="project_content_wrap kaizen_banner" />
  //         {/* <div className="head_logo_wrap">
  //           <img src={kaizen_logo} alt="Logo" className="project_content_wrap kaizen_logo" />
  //           <img src={kaizen_txt} alt="Banner" className="project_content_wrap kaizen_txt" />
  //         </div> */}
  //         {/* </div> */}
  //         {/* <div className="section_heading prmry_head">Introduction</div> */}
  //         <div className="project_content_wrap">
  //           {/* Kaizen is mobile application that assists basketball coaches in draft and communicate strategies before and during
  //           games. */}
  //         </div>
  //       </div>
  //       <div className="project_secction">
  //         <div className="section_heading prmry_head prj_s_hd">Challenge</div>
  //         <div className="project_content_wrap">
  //           {/* The challenge was to design a mobile application that assists basketball coaches in drafting game tactics and
  //           communicating them to players. */}
  //           <img
  //             src={kaizen_designChallenge}
  //             alt="Logo"
  //             style={{ width: "100%" }}
  //             className="project_content_wrap cnt_crd kaizen_banner"
  //           />
  //         </div>
  //       </div>
  //       <div className="project_secction">
  //         <div className="section_heading prmry_head prj_s_hd">Design Frame</div>
  //         <div className="project_content_wrap">
  //           <img
  //             src={kaizen_designFrame}
  //             alt="Logo"
  //             style={{ width: "100%" }}
  //             className="project_content_wrap cnt_crd kaizen_banner"
  //           />
  //         </div>
  //       </div>
  //       <div className="project_secction">
  //         <div className="section_heading prmry_head prj_s_hd">Solution</div>
  //         <div className="project_content_wrap">
  //           <img
  //             src={kaizen_designSolution}
  //             alt="Logo"
  //             style={{ width: "100%" }}
  //             className="project_content_wrap cnt_crd kaizen_banner"
  //           />
  //         </div>
  //       </div>
  //       <div className="project_secction">
  //         <div className="section_heading prmry_head prj_s_hd">Design Process</div>
  //         <div className="project_content_wrap">
  //           <div className="project_content_interviewSummary_wrap">
  //             <div className="project_content_interviewSummary_head scndry_head">Interview Summary</div>
  //             <div className="project_content_interviewSummary">
  //               <img
  //                 src={kaizen_processInsight}
  //                 alt="Logo"
  //                 style={{ width: "100%" }}
  //                 className="project_content_wrap cnt_crd kaizen_banner"
  //               />
  //             </div>
  //           </div>
  //           <div className="project_content_foa_wrap">
  //             <div className="project_content_foa_head scndry_head">Features of App</div>
  //             <div className="project_content_foa">
  //               <img
  //                 src={kaizen_processFeatures}
  //                 alt="Logo"
  //                 style={{ width: "100%" }}
  //                 className="project_content_wrap cnt_crd kaizen_banner"
  //               />
  //             </div>
  //           </div>
  //           <div className="project_content_persona_wrap">
  //             <div className="project_content_persona_head scndry_head">Persona</div>
  //             <div className="project_content_persona">
  //               <img src={kaizen_processPersona} alt="Logo" className="project_content_wrap persona" />
  //             </div>
  //           </div>
  //           <div className="project_content_persona_wrap">
  //             <div className="project_content_persona_head scndry_head">Gamification </div>
  //             <ul className="project_content_gamification">
  //               {gf_bd.map(gf_img => {
  //                 const gf_path = "../../img/projects/kaizen/files/gamification_breakdown/";
  //                 return (
  //                   <li className="gf_bd_li">
  //                     <img
  //                       className="gf_bd_img"
  //                       style={{ border: "0.25px solid #dbd3ce", borderRadius: "12px" }}
  //                       src={gf_img}
  //                       alt="Gamification"
  //                     />
  //                   </li>
  //                 );
  //               })}
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="project_secction">
  //         <div className="section_heading prmry_head prj_s_hd">Execution</div>
  //         <div className="project_content_wrap">
  //           <div className="project_content_wireframes_wrap">
  //             <div className="project_content_wireframes_head scndry_head">Wireframes</div>
  //             <div className="project_content_wireframes">
  //               <Masonry columnsCount={window.screen.width > 470 ? 6 : 3} gutter="7.5px">
  //                 {wrfms.map((image, i) => (
  //                   <div style={{}}>
  //                     <img
  //                       alt="msn"
  //                       key={i}
  //                       src={image}
  //                       style={{ width: "100%", display: "flex", borderRadius: "12px", border: "0.25px solid #dbd3ce" }}
  //                     />
  //                   </div>
  //                 ))}
  //               </Masonry>
  //               {/* #9d9691 */}
  //             </div>
  //           </div>
  //           <div className="project_content_wireframes_wrap">
  //             <div className="project_content_vdesign_head scndry_head">Visual Design</div>
  //             <div className="project_content_sketches">
  //               <Masonry columnsCount={window.screen.width > 470 ? 6 : 3} gutter="7.5px">
  //                 {vsl_dsgn.map((image, i) => (
  //                   <div style={{}}>
  //                     <img
  //                       alt="msn"
  //                       key={i}
  //                       src={image}
  //                       style={{ width: "100%", display: "flex", borderRadius: "12px", border: "0.25px solid #dbd3ce" }}
  //                     />
  //                   </div>
  //                 ))}
  //               </Masonry>
  //             </div>
  //           </div>
  //           {/* <div className="project_content_wireframes_wrap">
  //             <div className="project_content_vdesign_head scndry_head">Mockups</div>
  //             <div className="project_content_sketches">...</div>
  //           </div> */}
  //           <div className="project_content_wireframes_wrap">
  //             <div className="project_content_vdesign_head scndry_head">Prototype</div>
  //             <div className="project_content_sketches">
  //               <div className="project_content_txt">N/A</div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="project_secction">
  //           <div className="section_heading prmry_head prj_s_hd">Conclusion</div>
  //           <div className="project_content_wrap">
  //             <div className="project_content_interviewSummary_wrap">
  //               <div className="project_content_interviewSummary_head scndry_head">Learnings</div>
  //               <div className="project_content_txt">
  //                 The application addresses the issue of drafting game tactics, and communicate game tactics from the point of
  //                 view of the coach. The application should focus on the user experience of the players
  //               </div>
  //             </div>
  //             <div className="project_content_foa_wrap">
  //               <div className="project_content_foa_head scndry_head">Next Steps</div>
  //               <div className="project_content_txt">
  //                 The next phase would be building an interface from the players perspective
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="project_secction">
  //           <div className="section_heading prmry_head prj_s_hd">More Projects</div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  // V2
  return (
    <div>
      <div className="project_wrap">
        <img src={kaizen_banner2} alt="Logo" className="project_banner" />
        <img src={kaizen_scope} alt="Logo" className="project_banner" />
        <img src={kaizen_research} alt="Logo" className="project_banner" />
        <img src={kaizen_execution} alt="Logo" className="project_banner" />
        <img src={kaizen_finalSolution} alt="Logo" className="project_banner" />

        <div className="prototype_container" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="prototype"
            style={{
              fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', color: '#F96706', marginBottom: '20px'
            }}>
            Play Prototype
          </div>
          <VideoPlayer videoId='dL0q6fCmgYM' />
          {/* <div className="prototype_text">Play<br /> Prototype</div> */}
        </div>

        <img src={kaizen_mockup} alt="Logo" className="project_banner" />
        <img src={kaizen_conclusion} alt="Logo" className="project_banner" />
      </div>
    </div>
  )

}
