import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
import "./style/projects.css";
import './style/v2.css'

// import { pdfjs } from 'react-pdf';

import { ExtractMediaName } from "../../util/extractMediaName";
import { VideoPlayer } from "../../util/Youtube";
import { Fade, JackInTheBox, Zoom } from "react-awesome-reveal";

export const FFWRedesign = () => {

    // PDF Variable----->
    // const [numPages, setNumPages] = useState(0);
    // const [pageNumber, setPageNumber] = useState(1);

    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    // }

    function importAll(r) {
        return r.keys().map(r);
    }

    const designsV2 = importAll(
        require.context("../../img/projects/ffw_redesign/v2", false, /\.(png|jpe?g|svg)$/)
    );

    return (
        <div style={{}}>
            <div className="project_wrap">
                {/* <Zoom bottom> */}
                {
                    designsV2?.map((dsgnv2, i) => {
                        return (
                            <div key={i}>
                                <Fade cascade damping={0.4}>
                                    <img src={dsgnv2} alt={ExtractMediaName(dsgnv2)} className="project_banner" />
                                    {/* {
                                        console.log('cut url: ', ExtractMediaName(dsgnv2))}
                                    {
                                        console.log(dsgnv2)
                                    } */}
                                    {
                                        ExtractMediaName(dsgnv2) === "4ffw_execution_3" &&
                                        <div className="prototype_container" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className="prototype"
                                                style={{
                                                    fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', color: '#F96706'
                                                }}>
                                                Play Prototype
                                            </div>
                                            <br />
                                            <VideoPlayer videoId='hPqJYB-4qsE' />
                                        </div>
                                    }
                                </Fade>
                            </div>
                        )
                    })
                }
                {/* </Zoom> */}
            </div>

            {/* <img src={ffw_banner} alt="Logo" className="project_banner" /> */}
            {/* <img src={ffw_caseStudy} alt="Logo" className="project_banner" /> */}


            {/* PDF Viewer */}
            {/* <div className='' style={{ padding: '25px', width: '1200px' }}>
                <Document loading="Pease wait. I a loading..."
                    onWaiting={e => { console.log('pdf waiting') }}
                    file={caseStudy} onLoadSuccess={onDocumentLoadSuccess} >
                    <Page
                        pageNumber={pageNumber}
                        width={1200}
                    // height={1000}
                    
                    />
                </Document>
            </div>
            <p>
                Page {pageNumber} of {numPages}
            </p> */}
        </div>
    );
};
