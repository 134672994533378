import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "./style/projects.css";
import './style/v2.css';

import { ExtractMediaName } from "../../util/extractMediaName";
import { VideoPlayer } from "../../util/Youtube";
import { Fade, JackInTheBox, Zoom } from "react-awesome-reveal";

export const StockSync = () => {

    const [images, setImages] = useState(null)

    function importAll(r) {
        return r.keys().map(r);
    }
    const designsV2 = importAll(
        require.context("../../img/projects/stocksync/files", false, /\.(png|jpe?g|svg)$/)
    );

    useEffect(() => {

        setTimeout(() => {
            setImages(designsV2);
        }, 2000);

    }, [])

    return (
        <div style={{}}>
            <div className="project_wrap">
                {
                    images?.map((image, i) => {
                        if (image) {
                            return (
                                <div key={i}>
                                    <Fade cascade damping={0.4}>
                                        <img src={image} alt={ExtractMediaName(image)} className="project_banner" />
                                        {
                                            image ?
                                                ExtractMediaName(image) === "4stocksync_execution" &&
                                                <div className="prototype_container" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div className="prototype"
                                                        style={{
                                                            fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', color: '#209E75'
                                                        }}>
                                                        Play Prototype
                                                    </div>
                                                    <br />
                                                    <VideoPlayer videoId='X3FwBu5Abvo' />
                                                </div>
                                                :
                                                <Skeleton height={100} width={200} />
                                        }
                                    </Fade>
                                </div>
                            )
                        } else {
                            <>
                                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                                    <Skeleton height={100} width={200} />
                                    <Skeleton height={50} width={150} />
                                    <Skeleton height={150} width={300} />
                                    {/* Add more Skeleton components as needed */}
                                </SkeletonTheme>
                            </>
                        }
                    })
                }
            </div>
        </div>
    )
}
