export const ExtractMediaName = (url) => {
    const lastSlashIndex = url.lastIndexOf('/');

    if (lastSlashIndex !== -1) {
        const fileNameWithExtension = url.slice(lastSlashIndex + 1);
        const dotIndex = fileNameWithExtension.indexOf('.');

        if (dotIndex !== -1) {
            return fileNameWithExtension.slice(0, dotIndex);
        }
    }

    return "Invalid URL structure";
}