import React from "react";
import Masonry from "react-responsive-masonry";
import "./style/projects.css";
import './style/v2.css';
import { ExtractMediaName } from "../../util/extractMediaName";
import { VideoPlayer } from "../../util/Youtube";

import { Fade, JackInTheBox, Zoom } from "react-awesome-reveal";

// https://youtu.be/7yR2iT4BPic

// import nudge_banner from "../../img/projects/nudge/files/nudge_banner.svg";
// // import kaizen_designChallenge from "../../img/projects/kaizen/files/kaizen_designChallenge.png";

export default function Nudge() {

  function importAll(r) {
    return r.keys().map(r);
  }

  const designsV2 = importAll(
    require.context("../../img/projects/nudge/v2", false, /\.(png|jpe?g|svg)$/)
  );

  return (

    <div>

      <div className="project_wrap">
        {/* <Zoom bottom> */}
        {
          designsV2?.map((dsgnv2, i) => {
            return (
              <div key={i}>
                <Fade cascade damping={0.4}>
                  <img src={dsgnv2} alt="Logo" className="project_banner" />
                  {/* {
                    console.log('cut url: ', ExtractMediaName(dsgnv2))}
                  {
                    console.log(dsgnv2)
                  } */}
                  {
                    ExtractMediaName(dsgnv2) === "6nudge_finalSolution" &&
                    <div className="prototype_container" style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="prototype"
                        style={{
                          fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', color: '#F96706'
                        }}>
                        Play Prototype
                      </div>
                      <br />
                      <VideoPlayer videoId='7yR2iT4BPic' />
                    </div>
                  }
                </Fade>
              </div>
            )
          })
        }
        {/* </Zoom> */}
      </div>

    </div>

  );
}
