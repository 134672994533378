import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./screens/home";
import Navbar from "./screens/navbar/navbar";
import About from "./screens/about";
// import CV from "./screens/cv";
import ProjectLanding from "./screens/project_landing";
import Err404 from "./screens/404";
import Nudge from "./screens/projects/nudge";
import Kaizen from "./screens/projects/kaizen";
import Footer from "./screens/navbar/footer";
import ScrollToTopOnPageChange from "./util/scrollToTop";
import { StockSync } from "./screens/projects/stocksync";
import { FFWRedesign } from "./screens/projects/ffw_redesign";

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTopOnPageChange />
      <Routes>

        <Route path="/" Component={Home} />
        <Route path="/contact" Component={About} />
        {/* <Route path='/cv' Component={CV}/> */}
        <Route path="/projects" element={<ProjectLanding />}>
          <Route path="nudge" index element={<Nudge />} />
          <Route path="kaizen" index element={<Kaizen />} />
          <Route path="stocksync" index element={<StockSync />} />
          <Route path="ffw_redesign" index element={<FFWRedesign />} />
        </Route>
        <Route path="*" Component={Err404} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
