import React from 'react';
import YouTube from 'react-youtube';

// dL0q6fCmgYM - kaizen


export const VideoPlayer = ({ videoId }) => {

    const opts = {
        height: '500',
        borderRadius: '15px',
        width: '100%',
        minWidth: '50vw',
        playerVars: {
            autoplay: 0, // Set to 1 for autoplay
        },
    };

    const containerStyles = {
        width: '100%',
        borderRadius: '20px', // Adjust the border radius as needed
        overflow: 'hidden', // Ensure the rounded corners are applied
        border: '1px solid #B5AFAB'
        // position: 'absolute'
        // margin: '20px'
    };

    return (
        <YouTube videoId={videoId} opts={opts} style={containerStyles} />
    )
}
