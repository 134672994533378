import React from "react";

export default function Footer() {
  const cr_pg = window.location.pathname;

  return (
    <div
      className="footer"
      // style={{ position: cr_pg === "/" ? "relative" : "absolute" }}
    >
      © 2023 Engineered Imagination.
    </div>
  );
}
